// 表单公用间距
export const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
export const tailFormItemLayout = {
    wrapperCol: { span: 24 },
};

// 退出需要清除Cookie的key值
export const CLEAR_COOKIE_KEY = [
    "personMessage",
    "systemMessage",
    "emailTime",
    "phoneTime",
    "voiceTime",
    "dateTime",
    "ALBtype",
    "isAlbSecond",
    "isAlbThird",
    "isThird",
    "isWCThird",
    "withdrawLastTime",
];

// inbox
export const SYSMSG_TYPE_ID_ENUM = {ALL: '0', BANKING: '7', PRODUCTS: '8', PROMOTIONS: '9', GENERAL: '10'};
export const INBOXMSG_TYPE_ID_ENUM = {TRANSACTION: '2', PERSONAL: '1', DISCOUNT: '3' };
export const INBOXMSG_TYPE_OPTION_ID_ENUM = {ALL:'0', DEPOSIT:'3', TRANSFER:'4', WITHDRAWAL:'5', BONUS:'6'}; // MUST be string, depends on getPersonData's design/behavior
export const MESSAGE_TYPE_NAMES = {
    [INBOXMSG_TYPE_ID_ENUM.PERSONAL]: "Personal",
    [INBOXMSG_TYPE_ID_ENUM.BONUS]: "Bonus",
    [INBOXMSG_TYPE_ID_ENUM.TRANSACTION]: "Transaction",
}; 

export const isWindowOpenGame = ["VTG", "SGW", "SLS", "CML", "KPK"]; //不支持iframe的游戏放进去
export const requireUniqueProviders = ["TGP", "TG", "JKR"]; // call Launch api時需加上後綴，以成為Unique providerCode
export const isCookieNoOpenGame = []; //不支持第三方cookie

export const lobbyOnlyProduct = ["Sportsbook", "ESports"];
export const lobbyWithCategory = ["InstantGames", "LiveCasino", "Slot", "P2P", "KenoLottery"];

export const SportsbookGameCatCode = 'Sportsbook';
export const ESportsGameCatCode = 'ESports';
export const InstantGamesGameCatCode = 'InstantGames';
export const LiveCasinoGameCatCode = 'LiveCasino';
export const P2PGameCatCode = 'P2P';
export const SlotGameCatCode = 'Slot';
export const KenoLotteryGameCatCode = 'KenoLottery';
